<template>
    <section class="content">
		<div class="card">
            <div class="card-body">
				              <div class="row">
          <div class="col-9">
            <div class="row form-horizontal">
              <div class="col-auto">
                <label class="control-label my-2 ml-2">
                  <h6>Join Date :</h6>
                </label>
              </div>
              <div class="col-auto">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    ref="daterange"
                    class="form-control"
                    style="border-right: 0"
                  />
                  <div class="input-group-append">
                    <div
                      class="input-group-text"
                      style="background-color: #fff"
                    >
                      <span><span class="fa fa-calendar"></span></span>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <div class="col-3 text-right" v-if="false">
            <div class="btn-group" ref="toolbar">
              <button type="button" data-action="read" class="btn btn-default">
                <i class="fa fa-sync-alt"></i>
              </button>
              <button
                type="button"
                :disabled="downloading"
                v-on:click="downloadXlsx"
                data-action="export-xlsx"
                class="btn btn-default"
              >
                <i class="fa fa-download"></i> Download
              </button>
            </div>
          </div>
        </div>
				
				<table class="table table-hover" ref="tableuser" id="tablshipper">
					<thead>
						<tr>
							<th>ID</th>
							<th>NAMA</th>
							<th>JOIN</th>
							<th>SHIPPER</th>
							<th>REWARD COD</th>
							<th>REWARD ONGKIR</th>
							<th>STATUS</th>
						</tr>
					</thead>
					<tbody @click="handleClick">
					</tbody>
				</table>
				<div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
					<div class="modal-dialog" role="document">
						<div class="modal-content">
							<form role="form" @submit="submitForm">
								<div class="modal-header">
									<h5 class="modal-title">{{ formTitle }}</h5>
									<button type="button" class="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div class="modal-body">
									<p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
								<b>Please correct the following error(s):</b>
								<ul>
									<li v-for="error in errors" v-bind:key="error">{{ error }}</li>
								</ul>
								<button type="button" class="close" data-dismiss="alert" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
							</p>
									<div class="row">
										<div class="col-md-6 form-group" v-if="true">
											<label class="control-label">Reward COD</label>
											<input type="hidden" name="row_id" value="" />
											<input id="reward_cod" class="form-control" v-model="form.reward_cod" type="number" required="required" name="reward_cod" autocomplete="off" />
										</div>
                    <div class="col-md-6 form-group" v-if="true">
											<label class="control-label">Reward Ongkir</label>
											<input type="hidden" name="row_id" value="" />
											<input id="reward_ongkir" class="form-control" v-model="form.reward_ongkir" type="number" required="required" name="reward_ongkir" autocomplete="off" />
										</div>
                    <div class="col-md-6 form-group">
											<label class="control-label"> </label>
											<label class="checkbox control-label">
												<input value="1" checked="checked" type="checkbox" name="reward_status" v-model="form.reward_status"/>Active</label>
										</div>
									</div>
								</div>
								<div class="modal-footer">
									<button type="submit" class="btn btn-primary">Save changes</button>
									<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, formatCurrency,openChat } from "@/libs/hxcore";
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Swal from "sweetalert2";

export default {
  name: "Shipper",
  data() {
    return {
      errors: [],
      shipmentAmount: [{label: '1 - 5', num: 5}, {label: '6 - 30', num: 30}, {label: '31 - 100', num: 100}, {label: ' > 100', num: 1000}],
      method: "",
      roles: "",
      groups: [],
      memberType: [],
      userCS: [],
      bank: [],
      filter: {},
      dt1: moment().startOf("month"),
      dt2: moment(),
        data_type: "pickupTime",
      disabled: false,
      formTitle: "Tambah Shipper",
      form: {
        reward_cod: 0,
        reward_ongkir: 0,
        reward_status: "",
      },
    };
  },
  computed: {
    kurirLogo() {
      const fileName = "jne"; //logo.toLowerCase()

      return require(`../assets/img/${fileName}.png`); // the module request
    },
  },
  components: {
    vSelect,
},
watch:{
    $route (to, from){
      if(to.path === "/shipper/all")
      {
        sessionStorage.filterData='';
		sessionStorage.filterStatus = '';
        this.filter = {};
        this.table.api().ajax.reload();
      }
    }
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    this.userInfo = auth.user();

	if(this.$route.params.filter) {
      this.filter = this.$route.params.filter;
    }

	if(this.filter.dt1 && this.filter.dt2)
    {
      this.dt1 = moment(this.filter.dt1);
      this.dt2 = moment(this.filter.dt2);
    }
    this.dateStart = this.dt1.format('YYYY-MM-DD');
    this.dateEnd = this.dt2.format('YYYY-MM-DD');
    if(this.filter.gid)
    {
      this.groupId = this.filter.gid;
      this.dateStart = '';
      this.dateEnd = '';
	  }
    if(Object.keys(this.filter).length<1)
    {
      if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
    }
    else{
      sessionStorage.filterData = JSON.stringify(this.filter);
      sessionStorage.filterStatus = 1;
    }
    this.filter.datatype = this.data_type;

   authFetch("/shipper/member_type")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.memberType = js.data;
      });

    authFetch("/shipper/bank")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.bank = js.data;
      });

      authFetch("/shipper/kurir")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.groups = js.data;
      });
      this.loadCS();
  },
  methods: {
      loadCS: function () {
        this.roles = this.$route.meta.roles;
    this.userInfo = auth.user();
    this.filter.datatype = this.data_type;
    authFetch("/shipper/usercs")
        .then((res) => {
            if (res.status === 201) {} else if (res.status === 400) {}
            return res.json();
        })
        .then((js) => {
            this.userCS = js.data;
        });
    },
    getKurirLogo(kurir) {
      const fileName = kurir.toLowerCase();

      return require(`../assets/img/${fileName}.png`); // the module request
    },
    handleClick(e) {
    if (e.target.matches(".link-afiliasi")) {
      let route = this.$router.resolve({
        path: "/afiliasi-shipper-detail/" + e.target.dataset.to
      });
      window.open(route.href,"_blank");
    }
    },
    
    dataType(event) {
      this.data_type = event.target.value;
	  this.filter.datatype = this.data_type;
    //   this.table.api().ajax.reload();
    },
    onDate() {
      // var params = { dt1: this.dateStart, dt2: this.dateEnd };
      // var search = new URLSearchParams(params);
      this.table.api().ajax.reload();
    },
	downloadXlsx: function (e) {
        this.downloading = true;
		var table = $('#tablshipper').DataTable();
		var length = table.page.info().recordsTotal;
		
		if (length > 20000) {
			Swal.fire("Proses gagal", `Maksimal data AWB sebanyak kurang lebih 20.000 data.`, "error");
			this.downloading = false;
			return false;
		}
		
		var filter = JSON.parse(JSON.stringify(this.filter));
			filter.q = $("input[name=q]").val();
			filter.category = $("select[name=category]").val();

		console.log(filter);
		var data = Object.keys(filter)
			.map(
				(key) =>
				encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
			)
			.join("&");
		console.log(data)
		this.loadingContent = true;
		authFetch("/report/shipper/excell", {
				method: "POST",
				body: data,
			})
			.then((response) => {
				if (response.status === 201) {
				} else if (response.status === 400) {
				} else if (response.status === 500){
					Swal.fire("Timeout!", ``, "error");
					this.loading2 = false;
					this.msg = '';
					return false;
				}

				return response.blob();
			})
			.then((blob) => {
				setTimeout(() => {
					this.downloading = false;
				}, 1000);
				this.loadingContent = false;
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = "data.xlsx";
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove(); //afterwards we remove the element again
			});
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var allData = this.form;
      var urlSubmit = "/shipper/affiliasi/affiliator";
      if (this.method == "PUT") urlSubmit = "/shipper/affiliasi/affiliator/" + this.form.id;
      authFetch(urlSubmit, {
        method: this.method,
        body: JSON.stringify(allData),
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },

  mounted() {
    const e = this.$refs;
    var self = this;
    new Daterangepicker(
      this.$refs.daterange,
      {
        startDate: !this.dt1 ? moment().day(-31) : this.dt1,
        endDate: !this.dt2 ? moment() : this.dt2,
        locale: {
					format: 'DD/MM/YYYY'
				},
      },
      function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.data_type = self.data_type;
        self.filter.datatype = self.data_type;
        self.filter.dt1 = self.dateStart;
        self.filter.dt2 = self.dateEnd;
        self.onDate();
      }
    );
    //console.log('roles', this.$route.params.roles);
    this.table = createTable(e.tableuser, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/shipper/affiliasi/affiliator",
      frame: true,
      scrollX: true,
      processing: true,
      autoWidth:false,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
    
        paramData: function (d) {
            var filter = JSON.parse(JSON.stringify(self.filter));
            //d = filter;
            //d.dev = filter.delivered;
            d.dt1 = self.dateStart;
            d.dt2 = self.dateEnd;
            d.datatype = self.data_type;
            var filter = JSON.parse(JSON.stringify(self.filter));
            for (const key in filter) {
                d[key] = filter[key] === false ? 0 : filter[key];
            }
        },
      columns: [
        { data: "shipper_code" },
        { data: "nama",
        render: function (data, type, row, meta) {
          return (
              '<a class="link link-afiliasi text-info" style="cursor:pointer;" data-to="' + row.id + '">' + data + "</a>"
          );
        }
        },
        { data: "created_on",
        render: function (data, type, row, meta) {
          return moment(row.created_on).format("DD/MM/YYYY HH:mm");
        }, },
        { data: "followers" },
        { data: "reward_ongkir",
        render: function (data, type, row, meta) {
          var estimasi = data?data:0;
          return `${estimasi}%`;
        }, },
        { data: "reward_cod",
        render: function (data, type, row, meta) {
          var diterima = data?data:0;
          return `${diterima}%`;
        }, },
        { data: "reward_status",
        render: function (data, type, row, meta) {
          var diterima = ""
          if(data == 1){
            diterima = "On"
          }else if(data == 0){
            diterima = "Off"
          }
          return `${diterima}`;
        }, },
      ],
      filterBy: [0,1],
      rowCallback: function (row, data) {
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Edit Reward";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form.id = evt.data.id;
          self.form.reward_cod = evt.data.reward_cod;
          self.form.reward_ongkir = evt.data.reward_ongkir;
          if(evt.data.reward_status == 0 || !evt.data.reward_status){
            self.form.reward_status = false;
          }else{
            self.form.reward_status = true;
          }
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Shipper";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          self.form = evt.data;
          authFetch("/shipper/" + evt.data.id, {
            method: "DELETE",
            body: "id=" + evt.data.id,
          })
            .then((res) => {
              return res.json();
            })
            .then((js) => {
              this.table.api().ajax.reload();
            });
        }
      },
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
    });
  },
};
</script>
<style type="text/css">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>